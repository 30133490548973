import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { GuardProps } from 'types';
import { useEffect } from 'react';
import { SETUP_MFA_PATH } from 'config';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
    const { isLoggedIn, shouldSkipSetupMFA } = useAuth();
    const navigate = useNavigate();
    const storageshouldSkipSetupMFA = window.localStorage.getItem('shouldSkipSetupMFA');
    useEffect(() => {
        if (!shouldSkipSetupMFA && !storageshouldSkipSetupMFA) {
            navigate(SETUP_MFA_PATH, { replace: true });
        }
        if (!isLoggedIn) {
            navigate('login', { replace: true });
        }
    }, [isLoggedIn, navigate, shouldSkipSetupMFA, storageshouldSkipSetupMFA]);

    return children;
};

export default AuthGuard;
