import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    Typography
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import React, { ReactNode, useState, useRef, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { ModalContextType } from 'hooks/useConfirmationModal';

export const ConfirmationModalContext = React.createContext<ModalContextType>({} as ModalContextType);

export interface IDialogData {
    title: string;
    icon?: JSX.Element;
    onForward: (() => Promise<void>) | null;
    onAction: (() => void) | null;
    content: string;
    forwardButtonText?: string;
    actionButtonText?: string;
    loadingText?: string;
    showCheckbox: boolean;
    sectionTitle: string;
    actionType: string;
    recordType: string;
}
export const ConfirmationModalContextProvider = ({ children }: { children?: ReactNode | undefined }) => {
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [checked, setChecked] = useState(false);
    const [data, setData] = useState<IDialogData>({
        title: '',
        content: '',
        forwardButtonText: 'Confirm',
        loadingText: 'Loading',
        onForward: null,
        onAction: null,
        showCheckbox: false,
        actionType: '',
        sectionTitle: '',
        recordType: ''
    });

    const resolver = useRef<(value: boolean) => void>();

    useEffect(() => {
        // Validate local storage
        const tenantName = localStorage.getItem('tenantPath')?.replaceAll('/', '');
        const checkedConfiguration = localStorage.getItem(
            `checked_${tenantName}_${data.sectionTitle}${
                data.recordType ? `_${data.recordType}_${data.actionType}` : `_${data.actionType}`
            }`
        );
        if (checkedConfiguration) {
            setShowConfirmationModal(false);
            data.onForward?.();
        }
        if (!checkedConfiguration && data.title) {
            setShowConfirmationModal(true);
        }
    }, [data]);

    const handleShow = (newData: IDialogData): Promise<boolean> => {
        setChecked(false);
        setData((s) => ({ ...s, ...newData }));

        return new Promise((resolve) => {
            resolver.current = resolve;
        });
    };

    const handleOk = async () => {
        if (data.showCheckbox && checked) {
            const tenantName = localStorage.getItem('tenantPath')?.replaceAll('/', '');
            localStorage.setItem(
                `checked_${tenantName}_${data.sectionTitle}${
                    data.recordType ? `_${data.recordType}_${data.actionType}` : `_${data.actionType}`
                }`,
                'checked'
            );
        }
        await data.onForward?.();
        resolver.current && resolver.current(true);
        setShowConfirmationModal(false);
    };

    const handleAction = async () => {
        await data.onAction?.();
        resolver.current && resolver.current(false);
        setShowConfirmationModal(false);
    };

    const handleClose = async () => {
        resolver.current && resolver.current(false);
        setShowConfirmationModal(false);
    };

    return (
        <ConfirmationModalContext.Provider
            value={{ showConfirmation: handleShow, hideConfirmation: () => setShowConfirmationModal(false) }}
        >
            {children}

            <Dialog
                open={showConfirmationModal}
                onClose={() => setShowConfirmationModal(false)}
                sx={{ '& .MuiPaper-root': { padding: 0 } }}
            >
                <DialogTitle component="div" sx={{ paddingX: 1 }}>
                    <Grid container alignItems="center">
                        <>
                            {data.icon && data.icon}
                            <Typography variant="h3" sx={{ flexGrow: 1, marginLeft: '1rem' }}>
                                {data.title}
                            </Typography>
                        </>
                        <IconButton onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Grid>
                </DialogTitle>
                <DialogContent sx={{ marginY: 2 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'normal', color: 'black' }} component="div">
                        {data.content}
                    </Typography>
                    {data.showCheckbox === true && (
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        defaultChecked
                                        checked={checked}
                                        onChange={(event) => setChecked(event.target.checked)}
                                        sx={(theme) => ({
                                            color: theme.palette.secondary.main,
                                            '&.Mui-checked': { color: theme.palette.secondary.main }
                                        })}
                                    />
                                }
                                label="Don't show this again"
                            />
                        </FormGroup>
                    )}
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Button disabled={false} variant="outlined" color="secondary" onClick={handleAction}>
                        {data.actionButtonText || 'Cancel'}
                    </Button>
                    <LoadingButton
                        loading={false}
                        color="secondary"
                        onClick={handleOk}
                        loadingIndicator={data.loadingText}
                        variant="contained"
                    >
                        {data.forwardButtonText || 'Confirm'}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </ConfirmationModalContext.Provider>
    );
};
