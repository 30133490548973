const colors = {
    light: {
        primary: {
            '200': '#F5F5F5',
            '300': '#F5F6F7',
            '400': '#858585',
            '800': '#132145',
            main: '#203461',
            light: '#e4e7ec',
            dark: '#37326E'
        },
        secondary: {
            '200': '#7B93CB',
            '800': '#5D599E',
            main: '#37326E',
            light: '#EBEEFE',
            dark: '#37326E'
        }
    },
    dark: {
        primary: {
            '200': '#b0b6c4',
            '800': '#44506b',
            main: '#606d88',
            light: '#ecedf1',
            dark: '#586580'
        },
        secondary: {
            '200': '#f6a0bd',
            '800': '#e42a5d',
            main: '#ec407a',
            light: '#fde8ef',
            dark: '#ea3a72'
        }
    }
};

export default colors;
