// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconLayoutDashboard } from '@tabler/icons';
import { DashboardMenuProps } from './dashboard';

// constant
const icons = { IconLayoutDashboard };

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const settings: DashboardMenuProps = {
    id: 'settings',
    title: <FormattedMessage id="settings" />,
    type: 'group',
    children: [
        {
            id: 'dashboard-management',
            title: <FormattedMessage id="dashboardManagement" />,
            type: 'item',
            icon: icons.IconLayoutDashboard,
            url: 'backoffice/dashboard-management'
        }
    ]
};

export default settings;
