import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { DASHBOARD_PATH, SETUP_MFA_PATH } from 'config';
import { GuardProps } from 'types';
import { useEffect } from 'react';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }: GuardProps) => {
    const { isLoggedIn, shouldSkipSetupMFA } = useAuth();
    const navigate = useNavigate();
    const storageshouldSkipSetupMFA = window.localStorage.getItem('shouldSkipSetupMFA');

    useEffect(() => {
        if (isLoggedIn) {
            if (!shouldSkipSetupMFA && !storageshouldSkipSetupMFA) {
                navigate(SETUP_MFA_PATH, { replace: true });
            } else {
                navigate(DASHBOARD_PATH, { replace: true });
            }
        }
    }, [isLoggedIn, navigate, shouldSkipSetupMFA, storageshouldSkipSetupMFA]);

    return children;
};

export default GuestGuard;
