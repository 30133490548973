// types
import { ConfigProps } from 'types/config';

export const JWT_API = {
    secret: 'SECRET-KEY',
    timeout: '1 days'
};

export const FIREBASE_API = {
    apiKey: 'AIzaSyBPkZDF8VdN1fwQebPwK5vF5PcDk4pFzfY',
    authDomain: 'green-antonym-349903.firebaseapp.com',
    projectId: 'green-antonym-349903',
    storageBucket: 'green-antonym-349903.appspot.com',
    messagingSenderId: '528361065432',
    appId: '1:528361065432:web:15b6c177f3aceae26080ad',
    measurementId: 'G-ZCQWBH7Y9B'
};

// export const AUTH0_API = {
//     client_id: '7T4IlWis4DKHSbG8JAye4Ipk0rvXkH9V',
//     domain: 'dev-w0-vxep3.us.auth0.com'
// };

export const AWS_API = {
    region: process.env.REACT_APP_COGNITO_REGION || 'us-east-1',
    poolId: process.env.REACT_APP_COGNITO_POOL_ID || 'us-east-1_Iehx6E61K',
    appClientId: process.env.REACT_APP_COGNITO_CLIENT_ID || '3sfoogpl2j5vb22iplaoscdt4',
    accessKeyId: process.env.REACT_APP_COGNITO_ACCESS_KEY_ID || '',
    secretAccessKey: process.env.REACT_APP_COGNITO_SECRET_ACCESS_KEY_ID || ''
};

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

export const SETUP_MFA_PATH = `/setupmfa`;

export const DASHBOARD_PATH = '/dashboard';

const config: ConfigProps = {
    fontFamily: `'Poppins', sans-serif`,
    borderRadius: 4,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    container: false
};

export default config;
